<template>
  <div
    class="c-app flex-row align-items-center" 
    style="background: url('https://www.kaft.com/static/images/pages/our_story_2008.jpg') no-repeat center; background-size: cover;"
    :class="{ 'c-dark-theme': $store.state.darkMode }"
  >
    <CContainer>

      <CRow class="justify-content-center">
        
        <CCol md="8">

          <CCardGroup>

            <CCard class="p-4">

              <CCardBody>

                <div class="flexCenter" v-if="loading">
                    <CSpinner color="primary"/>
                </div>

                <CForm v-else-if="type == 'login'">
                  <p class="text-muted"> <b> KAFT </b> PANEL</p>

                  <CInput
                    placeholder="Email Adres"
                    autocomplete="username email"
                    v-model="email"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                    </template>
                  </CInput>

                  <CInput
                    placeholder="Şifre"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked" />
                    </template>
                  </CInput>

                  <CRow>
                    <vue-recaptcha 
                      style="margin-left: 1rem; margin-bottom: .5rem;" 
                      ref="recaptcha" 
                      :sitekey="publicKey"
                      @verify="onVerify"
                      @expired="onExpired"
                      @error="onExpired"
                     />
                  </CRow>

                  <CRow>

                    <CCol col="6" class="text-left">
                      <CButton
                        style="background-color: #00d8c1; color: white"
                        class="px-5"
                        @click="login"
                        :disabled="!reCaptcha">
                          Giriş
                      </CButton>
                    </CCol>

                    <CCol col="6" class="text-right">
                      <CButton color="link" style="color: gray" class="px-0" @click="forgotPasswordAlert">Şifremi unuttum?</CButton>
                    </CCol>

                  </CRow>

                </CForm>

                <CForm v-else>
                  <h5>Merhaba, <b>{{ loggedUser.name }} </b></h5>
                  <p>
                    Panele giriş yapabilmek için lütfen <b> {{ loggedUser.emailAddress[0]+'***@'+loggedUser.emailAddress.split('@')[1] }} </b> adresine gönderilen güvenlik kodunu giriniz.
                  </p> 
                  <fieldset class='number-code'>
                  <div>
                    <input name='code' class='code-input' required @keyup="(e) => enterCode(0, e)" v-model="code[0]"/>
                    <input name='code' class='code-input' required @keyup="(e) => enterCode(1, e)" v-model="code[1]"/>
                    <input name='code' class='code-input' required @keyup="(e) => enterCode(2, e)" v-model="code[2]"/>
                    <input name='code' class='code-input' required @keyup="(e) => enterCode(3, e)" v-model="code[3]"/>
                    <input name='code' class='code-input' required @keyup="(e) => enterCode(4, e)" v-model="code[4]"/>
                    <input name='code' class='code-input' required maxlength="1" @keyup="(e) => enterCode(5, e)" v-model="code[5]"/>
                  </div>
                </fieldset>

                <div class="timer">
                  <span> {{ timerVal }} </span>
                  <CProgress :value="(timerVal / 180 * 100)"/>
                </div>

                <CRow>
                  <CButton color="link" :disabled="timerVal !== 0"  @click="login"> Kodu Tekrar Gönder</CButton>

                  <CCol col="6" class="text-left">
                    <CButton color="success" class="px-5" @click="verify">  Doğrula </CButton>
                  </CCol>

                  <CCol col="6" class="text-right">
                  </CCol>

                </CRow>
                
                </CForm>
              </CCardBody>
            </CCard>

            <CCard
              style="background-color: #282923; opacity: .9;"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <img src="/img/logo-light.svg" style="width: 85px;">
            </CCard>

          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    
    <CToaster :autohide="3000">
      <div v-for="(toast, index) in fixedToasts" :key="index">
        <CToast
          :key="'toast' + toast"
          :color="toastColor(index)"
          :show="true"
          :header="toastHeader(index)"
          >
            {{ toastMessage(index) }}
        </CToast>
      </div>
    </CToaster>

  </div>
</template>

<script>

import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      type: "login",
      code:[],
      timer: null,
      timerVal: 180,
      publicKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
      reCaptcha: null,
    };
  },
  components:{
    VueRecaptcha
  },
  computed: {
    loading: function(){
      return this.$store.getters.userLoading
    },
    loggedUser: function(){
      return this.$store.getters.userStatus.loggedUser
    },
    fixedToasts() {
      return this.$store.state.fixedToasts
    },
  },
  created() {
    localStorage.clear();
  },
  methods: {
    toastColor(toast) {
      return this.$store.state.toasts[toast].color
    },
    toastHeader(toast) {
      return this.$store.state.toasts[toast].header
    },
    toastMessage(toast) {
      return this.$store.state.toasts[toast].msg
    },

    onVerify(response) {
      this.reCaptcha = response;
    },

    onExpired() {
      this.reCaptcha = null;
    },

    async login() {
      const { email, password, reCaptcha } = this;
      if (email && password && reCaptcha) {

        let data = {
          email: email,
          password: password,
          headers: {'Content-Type': 'application/json; charset=UTF-8'}
        }

        await this.$store.dispatch('auth', data)

        if(this.$store.getters.userStatus.token){
          await localStorage.setItem("user", JSON.stringify(this.$store.getters.userStatus))
          window.location.href = '/';
        } else if(this.$store.getters.userStatus && this.$store.getters.userStatus.loggedUser){

          this.type = 'verify'
          this.timerVal = 180
          this.code = []
          this.timer = setInterval( () => {

            if (this.timerVal > 0) 
              this.timerVal--
            else 
              clearInterval(this.timer)
						

          }, 1000 )
        }else {
          this.type = 'login'
        }
      }
    },

    async verify() {
      const { email, password, code } = this;
      if (email && password) {

        let data = {
          email: email,
          password: password,
          code: code.join('')
        }

        await this.$store.dispatch('authVerify', data)

        if(this.$store.getters.verifyStatus && this.$store.getters.verifyStatus.token){
          await localStorage.setItem("user", JSON.stringify(this.$store.getters.verifyStatus))
          window.location.href = '/';
        }

      }
      this.code = []

    },

    enterCode(index, e){

      if(e.keyCode == 8){
        if((!this.code[index] || this.code[index] == '' ) && index > 0){
          document.getElementsByName('code')[index - 1].focus()
        }
      } else if(index !== 5 && this.code[index] ){

        if(this.code[index].length > 1){

          let tmpCode = this.code[index];

          for(var i = 0; i < tmpCode.length; i++){
            this.code[index + i] = tmpCode[i]
          }

          index = index + (tmpCode.length - 1)

        }

        index < 6 && document.getElementsByName('code')[index + 1].focus()

      }
    },

    forgotPasswordAlert() {
      alert("Yöneticiniz ile iletişime geçiniz!!");
    }
  },
};
</script>

<style scoped lang="scss" >
  .number-code {
    div {
      display: flex;
      input:not(:last-child) {
        margin-right: 16px;
      }
      input.code-input {
        font-size: 1.5em;
        width: 1em;
        text-align: center;
        flex: 1 0 1em;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid ;
        &:focus{
          outline: none;
        }
      }
    }
  }

  .timer {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    
    div {
      flex: 1;
      margin-left: .5rem;
    }
  }
</style>